@value mobile from "breakpoints.module.css";

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.notification {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: 4px;
    color: var(--black);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    animation: fadeIn 0.5s;
    max-width: 329px;
    width: 100%;
    z-index: 10;
    border: 1px dotted #A6A6A6;
}

.notification__closeIcon {
    margin: 0 0 16px auto;
    display: flex;
}

.notification__statusIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.notification__title {
    font-size: 1.1rem;
    margin-bottom: 16px;
}

.notification__desc {
    font-size: 0.8rem;
    margin-bottom: 30px;
    width: 66%;
    text-align: center;
}


@media mobile {
    .notification {
        width: auto;
        max-width: none;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
    }
}