@value mobile from "breakpoints.module.css";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes slideRight {
    100% {
        left: 0;
    }
}

@keyframes slideRight {
    100% {
        left: 0;
    }
}

.hero {
    overflow: hidden;
}

.mainPictureBackground {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.mainPicture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mainImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.navigation {
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    min-width: 383px;
    width: 25.3%;
    background-color: var(--white-tr);
    color: var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Krub-Medium", sans-serif;
    -webkit-animation: slideRight 1s forwards;
    animation: slideRight 1s forwards;
    z-index: 200;
}

.hero__location {
    margin-bottom: 74px;
}

.hero__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
}

.hero__headerContainer {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hero__logo {
    margin-top: 10px;
    cursor: pointer;
}

.hero__logoMobile {
    width: 171px;
    height: 50px;
}

.hero__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    color: var(--white);
    margin-top: 35%;
    position: relative;
}

.hero__heading h1 {
    font-size: 36px;
    animation: fadeIn 0.5s ease;
    line-height: 44px;
    margin-bottom: 8px;
}

.hero__heading span {
    font-size: 24px;
    animation: fadeIn 1s ease;
}

.hero__arrowDown {
    position: absolute;
    right: 16px;
    bottom: 60px;
}

@media mobile {
    .mainPictureBackground {
        height: 100svh;
    }

    .hero__location {
        margin-bottom: 0;
    }
}