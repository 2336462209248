@value tablet, mobile from "breakpoints.module.css";

.weOperateIn {
    height: 60vh;
    background-color: var(--map-blue);
    overflow: hidden;
}

.weOperateIn__container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.info {
    padding: 20px;
    border-radius: 24px;
    background-color: var(--white-tr);
    color: var(--white);
    display: flex;
    flex-direction: column;
    max-width: 40%;
    margin-left: 40px;
    z-index: 10;
}

.info__titleBox {
    margin-bottom: 16px;
}

.info__title {
    font-size: 24px;
    line-height: 42px;
}

.info__text {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 28px;
}

.info__button {
    background-color: var(--yellow);
    color: var(--black);
    text-transform: uppercase;
    cursor: pointer;
    padding: 16px 24px;
    border-radius: 16px;
    font-family: Krub-Medium, sans-serif;
    width: fit-content;
    font-size: 18px;
    line-height: 16px;
}

.info__button:hover {
    box-shadow: var(--yellow) 0px 5px 24px;
}

@media tablet {
    .weOperateIn {
        height: 40vh;
    }

    .info {
        max-width: 45%;
    }
}

@media screen and (min-device-width: 600px) and (max-device-width: 1023px) {
    .weOperateIn {
        height: fit-content;
    }

    .weOperateIn__container {
        flex-direction: column;
    }

    .info {
        max-width: 90%;
        margin: 40px 40px 0;
    }
}

@media mobile {
    .weOperateIn {
        height: fit-content;
        margin-bottom: 40px;
    }

    .weOperateIn__container {
        flex-direction: column;
    }

    .info {
        max-width: 100%;
        margin: 20px 16px;
    }

    .info__decoration {
        width: 100%;
    }

    .info__button {
        width: 100%;
    }

    .info__title {
        text-align: center;
    }
}