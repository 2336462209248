@value mobile, tablet from "breakpoints.module.css";

.team {
    height: fit-content;
    padding: 40px 0;
}

.team__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team__titleMb32 {
    margin-bottom: 32px;
}

.info__titleMb16 {
    margin-bottom: 16px;
}

.team__title {
    font-size: 24px;
    line-height: 42px;
}

.team__info {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.info__row {
    display: flex;
    gap: 20px;
}

.info {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--blue);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info_bgBlue, .info_bgBlueContacts {
    background-color: var(--baby-blue);
    border: none;
}

.info_about {
    display: block;
}

.info__title {
    font-size: 20px;
    line-height: 34px;
}

.info__text {
    font-size: 18px;
    line-height: 28px;
}

.info__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info__icon {
    width: 37px;
    height: 40px;
    margin-bottom: 24px;
}

.info__name {
    text-transform: uppercase;
    font-size: 24px;
    font-family: Krub-Medium, sans-serif;
    margin-bottom: 16px;
}

.info__position {
    font-size: 20px;
    text-transform: uppercase;
}

.info__contacts {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
}

.contacts__icon {
    width: 24px;
    height: 22px;
    margin-right: 5px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--blue);
}

@media tablet {
    .info__row {
        flex-wrap: wrap;
    }

    .info {
        min-width: 300px;
    }
}

@media mobile {
    .info__row > div:nth-child(2) {
        order: -1;
    }

    .info_bgBlueContacts {
        background-color: var(--white);
        border: 1px solid var(--blue);
    }
}