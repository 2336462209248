.tooltip {
    position: relative;
    display: flex;
}

.tooltip .tooltiptext {
    background-color: white;
    text-align: center;
    border-radius: 8px;
    padding: 5px;
    position: absolute;
    display: flex;
    z-index: 10;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    color: var(--black);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    cursor: text;
    opacity: 1;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -30px;
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}
