@value mobile from "breakpoints.module.css";

.container {
    position: relative;
    display: none;
}

@media mobile {
    .container {
        display: flex;
    }
}

.burgerContainer {
    user-select: none;
    transition: 1s ease;
    padding: 10px 0 10px 30px;
}

.burgerContainerOpen {
    z-index: 200;
    position: fixed;
    right: 16px;
    top: 35px;
    padding: 0 0 20px 30px;
}

.burger, .cross {
    width: 15px;
    height: 12px;
    position: relative;
    display: inline-block;
}

.burger span, .cross span {
    position: absolute;
    border-radius: 2px;
    transition: .4s cubic-bezier(.25, .54, .73, .61);
    height: 2px;
    width: 15px;
    display: block;
}

.burger span {
    margin-bottom: 5px;
    background-color: var(--white);
}

.burger span:last-child {
    margin-bottom: 0;
}

.burger span:nth-child(2) {
    top: 5px;
    left: 0;
}

.burger span:nth-child(3) {
    bottom: 0;
}

.cross span {
    background-color: var(--blue);
}

.cross span:nth-child(1), .cross span:nth-child(3) {
    transform: rotate(45deg);
    transition-delay: 50ms;
    animation: fadeIn 2s;
}

.cross span:nth-child(2) {
    display: none;
}

.cross span:nth-child(3) {
    transform: rotate(-45deg);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fullScreenMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--white);
    color: var(--blue);
    font-family: 'Krub-Medium', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 150;
    animation: fadeIn 0.5s;
}

