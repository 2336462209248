@value mobile, tablet from "breakpoints.module.css";

.formContainer {
    box-sizing: border-box;
    position: relative;
    display: flex;
    max-width: 707px;
    width: 100%;
    padding: 24px 16px;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #A6A6A6;
    color: var(--black);
}

.formTitle {
    margin-bottom: 24px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
}

.form {
    display: flex;
    flex-direction: column;
}

.inputGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

label {
    display: block;
    line-height: 24px;
}

.input {
    background-color: transparent;
    outline: none;
    border: 1px solid #A6A6A6;
    color: #A6A6A6;
    border-radius: 4px;
    padding: 11px 6px;
    margin-bottom: 16px;
    font-size: 18px;
    resize: none;
    line-height: 24px;
}

.input_filled, input:focus, .textArea_filled {
    border: 1px solid var(--black);
    color: var(--black);
}

.error {
    position: absolute;
    bottom: -5px;
    color: var(--red);
    line-height: 22px;
    font-size: 12px;
    transition: opacity 0.2s ease;
    animation: fadeIn 0.3s ease forwards;
}


.input input {
    background-color: transparent;
    color: var(--black);
    font-size: 18px;
    outline: none;
    border: none;
}

.submitButton {
    background: var(--yellow);
    color: var(--black);
    border-radius: 4px;
    padding: 12px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 18px;
    margin-top: 8px;
    line-height: 24px;
    font-weight: 400;
}

.submitButton:hover {
    box-shadow: var(--yellow) 0px 5px 24px;
}

@media tablet {
    .formContainer {
        width: 100%
    }
}

@media mobile {
    .formTitle {
        font-size: 1.25rem;
    }
}