.titleBox {
    position: relative;
    width: fit-content;
}

.title__decoration {
    height: 1px;
    width: 100%;
    background-color: var(--blue);
}

.title__decoration_white {
    background-color: var(--white);
}