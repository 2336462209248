@value mobile, tablet from "breakpoints.module.css";

.services {
    height: 80vh;
    display: flex;
    justify-content: center;
    padding: 80px 0 40px;
}

.services__container {
    display: flex;
    align-items: center;
    width: 100%;
}

.services__info {
    text-transform: uppercase;
    color: var(--black);
    text-align: center;
    width: 50%;
}

.services__titleBox {
    margin: 0 auto 16px;
}

.services__title {
    font-size: 24px;
    line-height: 42px;
}

.services__text {
    font-size: 18px;
    line-height: 28px;
}

.services__media {
    width: 50%;
    display: flex;
    justify-content: center;
}

.services__mediaContainer {
    width: 90%;
    border-radius: 8px;
    overflow: hidden;
}

.services__gifBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 411px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}


.services__videoLoading {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #E5E4E2 38%, #fafafa 54%);
    background-size: 1000px 640px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.services__gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.services__gifDesc {
    background-color: var(--grey);
    padding: 20px;
    text-align: center;
    color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services__closePopup {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    display: flex;
    z-index: 10;
}

.gifDesc__title {
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 32px;
}

.gifDesc__readMoreBox {
    display: flex;
    align-items: center;
    font-family: Krub, sans-serif;
    cursor: pointer;
    color: var(--black);
}

.gifDesc__readMore {
    font-size: 18px;
    margin-right: 16px;
}

.gifDesc__arrowRight {
    height: 14px;
    width: 8px;
    display: flex;
}


.gifDesc__text {
    font-size: 18px;
    text-align: left;
    line-height: 28px;
}

@media tablet {
    .services {
        height: fit-content;
    }

    .services__mediaContainer {
        width: 100%;
    }
}

@media mobile {
    .services {
        padding: 40px 0;

    }

    .services__container {
        flex-direction: column;
        width: 100%;
    }

    .services__info, .services__media {
        width: 100%;
    }

    .services__text {
        margin-bottom: 32px;
    }

    .services__mediaContainer {
        width: 100%;
    }

    .services__gifBox {
        min-height: 391px;
    }

    .services__gifBox_small {
        min-height: 234px;
        width: 100%;
    }

    .services__gif {
        width: 100%;
    }
}

