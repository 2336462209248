@value mobile from "breakpoints.module.css";

.footer {
    display: flex;
    background-color: #041D3F;
    justify-content: space-between;
    padding: 35px 40px;
    align-items: center;
}

.footer__logo {
    width: 236px;
    height: 70px;
    cursor: pointer;
}

.footer__location {
    color: var(--white);
}

@media mobile {
    .footer {
        padding: 40px 16px;
    }

    .footer__logo {
        width: 171px;
        height: 50px;
    }
}