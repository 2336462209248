@value mobile from "breakpoints.module.css";

.contactUs {
    padding: 40px 0 80px;
    height: fit-content;
}

.contactUs__container {
    display: flex;
    flex-direction: column;
}

.contactUs__titleBox {
    margin-bottom: 24px;
}

.contactUs__title {
    font-size: 24px;
    color: var(--black);
    line-height: 42px;
}

.contactUs__content {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.contactUs__maps, .contactUs__form {
    width: 50%;
}

.maps {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.maps h3 {
    margin-bottom: 16px;
    line-height: 28px;
    font-size: 20px;
}

.contactUs__form {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

@media mobile {
    .contactUs {
        padding: 40px 0 40px;
    }

    .contactUs__content {
        flex-direction: column;
    }

    .contactUs__maps, .contactUs__form {
        width: 100%;
    }
}