@value tablet, mobile from "breakpoints.module.css";

@keyframes zoomIn {
    0%, 100% {
        opacity: 0;
        transform: translateY(0px);
    }
    50% {
        opacity: 1;
        transform: translateY(-3px);
    }
}

.mapContainer {
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    right: 20px;
    width: 60%;
    overflow: hidden;
    height: max-content;
    display: flex;
}

.geography {
    fill: #316CBC;
    outline: none;
}

.pin {
    animation: zoomIn 3s infinite ease;
}

@media tablet {
    .mapContainer {
        width: 50%;
    }
}

@media screen and (min-device-width: 600px) and (max-device-width: 1023px) {
    .mapContainer {
        position: relative;
        width: 100%;
        top: unset;
        transform: unset;
    }
}

@media mobile {
    .mapContainer {
        position: relative;
        width: 100%;
        top: unset;
        transform: unset;
    }
}