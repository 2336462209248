@value tablet, mobile from "breakpoints.module.css";

.aboutUs {
    padding: 120px 0;
    display: flex;
    height: 80%;
}

.aboutUs__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.aboutUs__media {
    width: 500px;
    height: 500px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
}

.aboutUs__pic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 367px;
    width: 367px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutUs__info {
    width: 50%;
}

.info__titleBox {
    margin-bottom: 16px;
}

.info__title {
    font-size: 24px;
    color: var(--blue);
    line-height: 42px;
}

.info__text {
    color: var(--black);
    line-height: 28px;
}

.text_mb18 {
    margin-bottom: 18px;
}


@media tablet {
    .aboutUs__pic {
        height: 328px;
        width: 328px;
    }

    .aboutUs__media {
        width: 450px;
        height: 450px;
        max-width: unset;
    }

    .aboutUs__info {
        width: 45%;
    }

    .aboutUs__container {
        gap: 16px;
    }
}

@media screen and (min-device-width: 600px) and (max-device-width: 1023px) {
    .aboutUs__info {
        width: 90%;
    }
}

@media mobile {
    .aboutUs {
        padding: 80px 0 50px;
        height: 100%;
    }

    .aboutUs__container {
        display: flex;
        flex-direction: column-reverse;
    }

    .aboutUs__pic {
        height: 293px;
        width: 293px;
        max-width: 70%;
    }

    .aboutUs__media {
        width: 100%;
        height: auto;
    }

    .aboutUs__info {
        width: 100%;
        margin-bottom: 10px;
    }
}