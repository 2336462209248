.hidden {
    opacity: 0;
    transform: translateY(25px);
    transition: all 0.8s ease;
}

.visible {
    opacity: 1;
    transform: translateY(0);
}

.hiddenDecoration {
    opacity: 0;
    width: 10%;
    transition: all 1.2s ease;
}

.visibleDecoration {
    opacity: 1;
    width: 100%;
}