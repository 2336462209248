@value mobile from "breakpoints.module.css";

@font-face {
    font-family: 'Krub';
    src: url('../public/fonts/krub-regular-webfont.woff2') format('woff2'),
    url('../public/fonts/krub-regular-webfont.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Krub-Medium';
    font-weight: 500;
    src: url('../public/fonts/krub-medium-webfont.woff2') format('woff2'),
    url('../public/fonts/krub-medium-webfont.woff') format('woff');
    font-style: normal;
    font-display: block;
}


html {
    height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Krub", sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    height: 100%;
    min-height: 100vh;
    min-height: 100svh;
    font-size: 18px;
}

/* Color variables */
:root {
    --white: #F5F5F5;
    --black: #000;
    --blue: #0048AA;
    --map-blue: #508DDF;
    --baby-blue: #DAE7F9;
    --yellow: #FFE607;
    --grey: #D9D9D9;
    --white-tr: rgba(245, 245, 245, 0.6);
    --red: #ED1919;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    img {
        width: 100%;
        height: auto;
    }
}


.app {
    background: var(--white);
    position: relative;
}

section {
    height: 100vh;
    position: relative;
}

button {
    margin: 0;
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
}

button:hover {
    outline: none;
}

h1, h2, h3, h4, h5 {
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
}

h2, h3, h4, h5 {
    font-family: 'Krub-Medium', sans-serif;
}

p {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container {
    padding: 0 2.2rem;
    height: 100%;
}

@media mobile {
    body {
        font-size: 16px;
    }

    .container {
        padding: 0 1rem;
    }

    section {
        height: 100svh;
    }
}

svg {
    width: 100%;
    height: auto;
}
