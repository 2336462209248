.loaderContainer {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    z-index: 10;
    align-items: center;
    justify-content: center;
}


.progressBar__container {
    position: relative;
    height: 8px;
    width: 30%;
    background-color: var(--grey);
    border-radius: 8px;
}

.progressBar__endPic {
    position: absolute;
    right: 0;
    bottom: 16px;
    height: 80px;
    width: 80px;
}

.progressBar {
    height: 100%;
    background-color: var(--blue);
    transition: width 0.2s;
    border-radius: 8px;
    position: relative;
}

.progressBar__startPic {
    position: absolute;
    bottom: 16px;
    height: 32px;
    width: 32px;
    z-index: 20;
}

.videoBackground {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 100;
}

.video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}