@value mobile from "breakpoints.module.css";

.text {
    letter-spacing: 0.25px;
    font-size: 24px;
    text-transform: uppercase;
}

#rotatingCircleText {
    animation-name: rotate-circle;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes rotate-circle {
    to {
        transform: rotate(360deg);
    }
}

@media mobile {
    .text {
        font-size: 18px;
    }
}